<template>
	<section id="channel" class="align-center">
        <b-container class="py-5 text-center">
            <h1>Channel page coming soon...</h1>
        </b-container>
	</section>
</template>
<script>
    export default {
		name: 'Channel',
		computed: {
			channel() {
				return this.$store.getters.channel
			}
		}
    }
</script>
