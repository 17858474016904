<template>
	<div id="breakouts">
        <b-container>
            <b-row class="py-5">
                <b-col cols="12" class="py-5" v-for="(breakout, i) in breakouts" :key="i">
                    <div class="breakout">
                        <b-row align-v="center">
                            <b-col cols="12" lg="4" order="2" order-lg="1" class="pr-lg-0 pt-5 pt-lg-0">
                                <b-aspect aspect="16:9" class="bg-cover bg-scroll position-relative" :class="!breakout.thumbnail_url ? 'bg-secondary' : null" :style="'background-image: url('+breakout.thumbnail_url+');'">
                                    <div class="bg-dark text-light p-2 position-absolute">
                                        <p class="m-0 text-uppercase smaller">Breakout session</p>
                                    </div>
                                    <div class="h-100 w-100 position-absolute d-flex justify-content-center align-items-center" v-if="!breakout.thumbnail_url">
                                        <font-awesome-icon :icon="['fas', 'image']" size="4x" />
                                    </div>
                                </b-aspect>
                            </b-col>
                            <b-col cols="12" lg="8" order="1" order-lg="2">
                                <div class="pl-md-5">
                                    <h4 class="pb-3">{{ breakout.title }}</h4>
                                    <p class="pb-3">{{ breakout.description }}</p>
                                    <a :href="breakout.url" target="_blank" class="btn" :class="channel.skin_button_color ? 'btn-'+channel.skin_button_color : 'btn-primary'">{{ $t('common.visit') }}</a>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
            attendee() {
                return this.$store.getters.attendee
            },
            breakouts() {
                if (!this.asset || !this.asset.breakouts) return [] 
                try {
                    const filteredList = this.asset.breakouts.filter(breakout => {
                        const email = this.attendee.email ? this.attendee.email : this.attendee.upn
                        return breakout.participants ? breakout.participants.search(email) !== -1 : true
                    })
                    return filteredList
                }
                catch(err) {
                    console.log(err)
                    return []
                }
            },
            channel() {
				return this.$store.getters.channel
			}
		},
	}
</script>
